import React from "react"
import Lottie from "react-lottie"
import { css } from "@emotion/core"

//import for the two animations
import styles_main_animation from "./main-illustration-shema-monstock.webflow.css"

import logo_monstock_animation from "./fichier_animation/logo_monstock_center.json"

import { LoadAnimation_fr } from "./main_animation_load/main_animation_fr"
import { LoadAnimation_en } from "./main_animation_load/main_animation_en"


export default function FirstAnimation(props) {
    //animation logo monstock
    var defaultOptions_logo_monstock_animation = {
        loop: true,
        autoplay: true, 
        animationData: logo_monstock_animation,
    };

    var tab =[]
    if (
        //Variables for the first main animation fr
        props.langue == "fr-fr"
    ){
        tab = LoadAnimation_fr()
    }
    else {
        tab = LoadAnimation_en()
    }

    return (
        <section className="section-is-main_animation" css={styles_main_animation}>
            <div className="section-24" >
                <div className="div-block-251"></div>
                <div className="div-block-252">
                    <div className="automatisation">
                        <div className="lottie-animation-9"><Lottie className="lottie-animation-9" options={tab[0]} loading="lazy" decoding="async"/></div>
                        <div className="m-automation"><Lottie className="m-automation" options={tab[7]} loading="lazy" decoding="async"/></div>
                    </div>
                    <div className="supply-chain-etndu">
                        <div className="lottie-animation-8"><Lottie className="lottie-animation-8" options={tab[1]} loading="lazy" decoding="async"/></div>
                        <div className="m-supply-chain"><Lottie className="m-supply-chain" options={tab[8]} loading="lazy" decoding="async"/></div>
                    </div>
                    <div className="gestion-des-intervention">
                        <div className="lottie-animation-10"><Lottie className="lottie-animation-10" options={tab[2]} loading="lazy" decoding="async"/></div>
                        <div className="m-gestion-intervenntion"><Lottie className="m-gestion-intervenntion" options={tab[9]} loading="lazy" decoding="async"/></div>
                    </div>
                    <div className="gestion-des-command">
                        <div className="lottie-animation-7"><Lottie className="lottie-animation-7" options={tab[3]} loading="lazy" decoding="async"/></div>
                        <div className="m-gestion-des-commande"><Lottie className="m-gestion-des-commande" options={tab[10]} loading="lazy" decoding="async"/></div>
                    </div>
                    <div className="livraison-du-dernier-kilometre">
                        <div className="lottie-animation-11"><Lottie className="lottie-animation-11" options={tab[4]} loading="lazy" decoding="async"/></div>
                        <div className="m-livraison-dernnier-kilometre"><Lottie className="m-livraison-dernnier-kilometre" options={tab[11]} loading="lazy" decoding="async"/></div>
                    </div>
                    <div className="data-reporting">
                        <div className="lottie-animation-6"><Lottie className="lottie-animation-6" options={tab[5]} loading="lazy" decoding="async"/></div>
                        <div className="m-data-reporting"><Lottie className="m-data-reporting" options={tab[12]} loading="lazy" decoding="async"/></div>
                    </div>
                    <div className="gestion-des-stock">
                        <div className="lottie-animation-12"><Lottie className="lottie-animation-12" options={tab[6]} loading="lazy" decoding="async"/></div>
                        <div className="m-gestion-des-stock-flux"><Lottie className="m-gestion-des-stock-flux" options={tab[13]} loading="lazy" decoding="async"/></div>
                    </div>
                    <div className="logo-anime"><Lottie options={defaultOptions_logo_monstock_animation} loading="lazy" decoding="async"/></div>
                </div>
            </div>
        </section>
    )
}