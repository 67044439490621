//Work in progress issue 134

import React from "react"
import Lottie from "react-lottie"
import { css } from "@emotion/core"

//import of all picture
//import logo_Marketplace
import marketplace_g from "./Images_animation/marketplace_g.png"
import logo_Amazon_BeezUp from "./Images_animation/Fichier-852x.png"
import logo_Ebay_Etsy from "./Images_animation/Fichier-832x.png"
import logo_Cdiscount from "./Images_animation/x31WbK.tif2x.png"
import marketplace from "./Images_animation/marketplace.png"

//import logo_Optimized_route
import optimizedroute_g from "./Images_animation/optimizedroute_g.png"
import logo_Mapotempo from "./Images_animation/dSZOln.tif2x.png"
import logo_AntsRoute from "./Images_animation/Fichier-702x.png"
import logo_TourSolver from "./Images_animation/Fichier-712x.png"
import optimizedRoute from "./Images_animation/optimizedRoute.png"

//import logo_CRM
import crm_g from "./Images_animation/crm_g.png"
import logo_Salesforce from "./Images_animation/Fichier-1032x.png"
import logo_Sellsy from "./Images_animation/Fichier-582x.png"
import logo_HubSpot from "./Images_animation/Fichier-592x.png"
import crm from "./Images_animation/crm.png"

//import logo_E-commerce
import ecommerce_g from "./Images_animation/ecommerce_g.png"
import logo_Shopify_Magento from "./Images_animation/Fichier-862x.png"
import logo_Prestashop from "./Images_animation/Fichier-1012x.png"
import logo_Woocommerce from "./Images_animation/TzXP6g.tif2x.png"
import ecommerce from "./Images_animation/ecommerce.png"

//import logo_Point_of_sales_payment
import pointofsales_g from "./Images_animation/pointofsales_g.png"
import logo_Stripe from "./Images_animation/9YgiAN.tif2x.png"
import logo_Tactill from "./Images_animation/IxcouF.tif2x.png"
import logo_KerAwen from "./Images_animation/xrs1PI.tif2x.png"
import pointofsales from "./Images_animation/pointOfSalesPayment.png"

//import logo_3rd_party
import _3rdparty_g from "./Images_animation/3rdparty_g.png"
import logo_Google_Drive_Mail from "./Images_animation/Fichier-932x.png"
import logo_Exel_Zapier from "./Images_animation/Fichier-942x.png"
import _3rdparty from "./Images_animation/3rdParty.png"

//import logo_Iot
import iot_g from "./Images_animation/iot_g.png"
import logo_Abeeway from "./Images_animation/GtbQGx.tif2x.png"
import logo_A from "./Images_animation/Fichier-1172x.png"
import iot from "./Images_animation/iot.png"

//import logo_BI_Insights
import binsights_g from "./Images_animation/binsights_g.png"
import logo_Powerbi from "./Images_animation/Dsd3Nu2x.png"
import logo_Qlik from "./Images_animation/f4PESj.tif2x.png"
import binsights from "./Images_animation/biinsights.png"

//import logo_3PL_Logistics
import logistic_g from "./Images_animation/logistic_g.png"
import logo_Chronopost from "./Images_animation/0pMnkx.tif2x.png"
import logo_Colissimo from "./Images_animation/G9mMHD.tif2x.png"
import logo_Ups_Mondialrelay from "./Images_animation/Fichier-922x.png"
import logistic from "./Images_animation/logistic2x.png"

//import logo_ERP
import erp_g from "./Images_animation/erp_g.png"
import logo_Sap from "./Images_animation/Fichier-992x.png"
import logo_Sage from "./Images_animation/dhpYIH.tif2x.png"
import logo_Odoo from "./Images_animation/hYV97v.tif2x.png"
import erp from "./Images_animation/erp.png"

//import logo_Compatibilite
import comptabilite_g from "./Images_animation/comptabilite_g.png"
import logo_Ciel from "./Images_animation/Fichier-1002x.png"
import logo_Numm from "./Images_animation/0CjZha.tif2x.png"
import logo_Xero_Qb from "./Images_animation/Fichier-912x.png"
import comptabilite from "./Images_animation/comptabilite.png"


//import for lines animation lottie
// import line_3rdparty from "./gif_schema_interaction/line_3rdparty.json"
// import line_biinsights from "./gif_schema_interaction/line_biinsights.json"
// import line_comptabilite from "./gif_schema_interaction/line_comptabilite.json"
// import line_crm from "./gif_schema_interaction/line_crm.json"
// import line_ecommerce from "./gif_schema_interaction/line_ecommerce.json"
// import line_erp from "./gif_schema_interaction/line_erp.json"
// import line_iot from "./gif_schema_interaction/line_iot.json"
// import line_logistics from "./gif_schema_interaction/line_logistics.json"
// import line_marketplace from "./gif_schema_interaction/line_marketplace.json"
// import line_optimizedRoute from "./gif_schema_interaction/line_optimizedRoute.json"
// import line_pointOfsalesPayment from "./gif_schema_interaction/line_pointOfsalesPayment.json"


import ms_logo_center_fr from "./gif_schema_interaction/schema_ms_logo_center/Ms_logo_center_fr.json"
import ms_logo_center_en from "./gif_schema_interaction/schema_ms_logo_center/Ms_logo_center_en.json"

//import top animation
import schema_top_section_fr from "./gif_schema_interaction/Schema_top_section/Schema-top-section-FR_.json"
import schema_top_section_en from "./gif_schema_interaction/Schema_top_section/Schema-top-section-EN.json"

//mobile
import schema_top_section_mobile_fr from "./gif_schema_interaction/Schema_top_section/top-section-mobile-FR.json"
import schema_top_section_mobile_en from "./gif_schema_interaction/Schema_top_section/top-section-mobile-EN.json"

import css_schema_interaction from "./interaction-shema-monstock.webflow.css"

//Variables lottie for create line animation
// const defaultOptions_line_3rdparty_gif = {
//     loop: false,
//     autoplay: true, 
//     animationData: line_3rdparty,
// };

// const defaultOptions_line_biinsights_gif = {
//     loop: false,
//     autoplay: true, 
//     animationData: line_biinsights,
// };
  
// const defaultOptions_line_comptabilite_gif = {
//     loop: false,
//     autoplay: true, 
//     animationData: line_comptabilite,
// };
  
// const defaultOptions_line_crm_gif = {
//     loop: false,
//     autoplay: true, 
//     animationData: line_crm,
// };
  
// const defaultOptions_line_ecommerce_gif = {
//     loop: false,
//     autoplay: true, 
//     animationData: line_ecommerce,
// };
  
// const defaultOptions_line_erp_gif = {
//     loop: false,
//     autoplay: true, 
//     animationData: line_erp,
// };
  
// const defaultOptions_line_iot_gif = {
//     loop: false,
//     autoplay: true, 
//     animationData: line_iot,
// };
  
// const defaultOptions_line_logistics_gif = {
//     loop: false,
//     autoplay: true, 
//     animationData: line_logistics,
// };
  
// const defaultOptions_line_marketplace_gif = {
//     loop: false,
//     autoplay: true, 
//     animationData: line_marketplace,
// };
  
// const defaultOptions_line_optimizedRoute_gif = {
//     loop: false,
//     autoplay: true, 
//     animationData: line_optimizedRoute,
// };
  
// const defaultOptions_line_pointOfsalesPayment_gif = {
//     loop: false,
//     autoplay: true, 
//     animationData: line_pointOfsalesPayment,
// };

export default function InteractionLogo(props) { 
    return (
        <section className="section" css={css_schema_interaction}>
            <div className="section-2">
                <div className="div-block-248">
                    <div className="lottie-animation-13">
                        <Lottie
                            options={{
                                loop: true,
                                autoplay: true,
                                animationData: props.langue === "fr-fr" ? schema_top_section_mobile_fr : schema_top_section_mobile_en,
                            }}
                        ></Lottie>
                    </div>
                    <div className="lottie-animation-5">
                        <Lottie
                            options={{
                                loop: true,
                                autoplay: true, 
                                animationData: props.langue === "fr-fr" ? schema_top_section_fr : schema_top_section_en, 
                            }}   
                        />
                    </div>
                </div>
            </div>
            <div className="section-3">
                <div className="div-block-235">
                    <div className="div-block-232">
                        <div className="div-block-233">
                            <img
                                src={marketplace_g}
                                alt="logo_inactif_marketplace"
                                loading="lazy"
                                decoding="defer"
                            />
                        </div>
                        <div className="div-block-234">
                            <img
                                src={marketplace}
                                alt="logo_actif_marketplace"
                                loading="lazy"
                                decoding="defer"
                            />
                            <div className="logo03">
                                <img
                                    src={logo_Amazon_BeezUp}
                                    alt="Logo Amazon and BeezUP"
                                    loading="lazy"
                                    decoding="defer">
                                </img>
                            </div>
                            <div className="logo02">
                                <img
                                    src={logo_Ebay_Etsy}
                                    alt="Logo Ebay and Etsy"
                                    loading="lazy"
                                    decoding="defer">
                                </img>
                            </div>
                            <div className="logo01">
                                <img
                                    src={logo_Cdiscount}
                                    alt="Logo Cdiscount"
                                    loading="lazy"
                                    decoding="defer">
                                </img>
                            </div>
                        </div>
                        <div className="div-block-238 marketplace">
                            {/* <Lottie 
                                options={defaultOptions_line_marketplace_gif}
                            /> */}
                        </div>
                    </div>
                    <div className="div-block-232 m1">
                        <div className="div-block-233">
                            <img
                               src={optimizedroute_g}
                               alt="logo_inactif_Optimized_route"
                               loading="lazy"
                                decoding="defer"
                            />
                        </div>
                        <div className="div-block-234">
                            <img
                                src={optimizedRoute}
                                alt="logo optimizedRoute"
                                loading="lazy"
                                decoding="defer">
                            </img>
                            <div className="logo03">
                                <img
                                    src={logo_Mapotempo}
                                    alt="logo_Mapotempo"
                                    loading="lazy"
                                    decoding="defer">
                                </img>
                            </div>
                            <div className="logo02 antsroute">
                                <img
                                    src={logo_AntsRoute}
                                    alt="logo  AntsRoute"
                                    loading="lazy"
                                    decoding="defer">
                                </img>
                            </div>
                            <div className="logo01">
                                <img
                                    src={logo_TourSolver}
                                    alt="logo TourSolver"
                                    loading="lazy"
                                    decoding="defer">
                                </img>
                            </div>
                        </div>
                        <div className="div-block-238 optimized-route">
                            {/* <Lottie 
                                options={defaultOptions_line_optimizedRoute_gif}
                            /> */}
                        </div>
                    </div>
                    <div className="div-block-232 m2">
                        <div className="div-block-233">
                            <img
                                src={crm_g}
                                alt="Logo inactif crm"
                                loading="lazy"
                                decoding="defer">
                            </img>
                        </div>
                        <div className="div-block-234">
                            <img
                                src={crm}
                                alt="Logo actif crm">
                            </img>
                            <div className="logo03 safeforce">
                                <img
                                    src={logo_Salesforce}
                                    alt="Logo Salesforce"
                                    loading="lazy"
                                    decoding="defer">
                                </img>
                            </div>
                            <div className="logo02">
                                <img
                                    src={logo_Sellsy}
                                    alt="Logo Sellsy"
                                    loading="lazy"
                                    decoding="defer">
                                </img>
                            </div>
                            <div className="logo01">
                                <img
                                    src={logo_HubSpot}
                                    alt="Logo HubSpot"
                                    loading="lazy"
                                    decoding="defer">
                                </img>
                            </div>
                        </div>
                        <div className="div-block-238 crm">
                            {/* <Lottie 
                                options={defaultOptions_line_crm_gif}
                            /> */}
                        </div>
                    </div>
                    <div className="div-block-232 m3">
                        <div className="div-block-233">
                            <img
                                src={ecommerce_g}
                                alt="Logo inactif ecommerce"
                                loading="lazy"
                                decoding="defer">
                            </img>
                        </div>
                        <div className="div-block-234">
                            <img
                                src={ecommerce}
                                alt="Logo actif ecommerce"
                                loading="lazy"
                                decoding="defer">
                            </img>
                            <div className="logo03 shopify">
                                <img
                                    src={logo_Shopify_Magento}
                                    alt="Logo Shopify and Magento"
                                    loading="lazy"
                                    decoding="defer">
                                </img>
                            </div>
                            <div className="logo02 presta">
                                <img
                                    src={logo_Prestashop}
                                    alt="Logo Prestashop"
                                    loading="lazy"
                                    decoding="defer">
                                </img>
                            </div>
                            <div className="logo01">
                                <img
                                    src={logo_Woocommerce}
                                    alt="Logo Woocommerce"
                                    loading="lazy"
                                    decoding="defer">
                                </img>
                            </div>
                        </div>
                        <div className="div-block-238 ecomerce">
                            {/* <Lottie 
                                options={defaultOptions_line_ecommerce_gif}
                            /> */}
                        </div>
                    </div>
                    <div className="div-block-232 m4">
                        <div className="div-block-233">
                            <img
                                src={pointofsales_g}
                                alt="Logo Point of Sales Payment inactif"
                                loading="lazy"
                                decoding="defer">
                            </img>
                        </div>
                        <div className="div-block-234">
                            <img
                                src={pointofsales}
                                alt="Logo Point of Sales Payment actif"
                                loading="lazy"
                                decoding="defer">
                            </img>
                            <div className="logo03 stripe">
                                <img
                                    src={logo_Stripe}
                                    alt="Logo Stripe"
                                    loading="lazy"
                                    decoding="defer">
                                </img>
                            </div>
                            <div className="logo02 tactill">
                                <img
                                    src={logo_Tactill}
                                    alt="Logo Tactill"
                                    loading="lazy"
                                    decoding="defer">
                                </img>
                            </div>
                            <div className="logo01">
                                <img
                                    src={logo_KerAwen}
                                    alt="Logo KerAwen"
                                    loading="lazy"
                                    decoding="defer">
                                </img>
                            </div>
                        </div>
                        <div className="div-block-238 sellofpoint">
                            {/* <Lottie 
                                options={defaultOptions_line_pointOfsalesPayment_gif}
                            /> */}
                        </div>
                    </div>
                    <div className="div-block-232 m5">
                        <div className="div-block-233">
                            <img
                                src={_3rdparty_g}
                                alt="Logo inactif 3rdparty"
                                loading="lazy"
                                decoding="defer">
                            </img>
                        </div>
                        <div className="div-block-234">
                            <img
                                src={_3rdparty}
                                alt="Logo actif 3rdparty"
                                loading="lazy"
                                decoding="defer">
                            </img>
                            <div className="logo03 non">
                                <img
                                    src={logo_Amazon_BeezUp}
                                    alt="Logo inexistant"
                                    loading="lazy"
                                    decoding="defer">
                                </img>
                            </div>
                            <div className="logo02 _3dparty2">
                                <img
                                    src={logo_Google_Drive_Mail}
                                    alt="Logo Google Drive and Google Mail"
                                    loading="lazy"
                                    decoding="defer">
                                </img>
                            </div>
                            <div className="logo01 _3dparty1">
                                <img
                                    src={logo_Exel_Zapier}
                                    alt="Logo Exel and Zapier"
                                    loading="lazy"
                                    decoding="defer">
                                </img>
                            </div>
                        </div>
                        <div className="div-block-238 _3dpart">
                            {/* <Lottie 
                                options={defaultOptions_line_3rdparty_gif}
                            /> */}
                        </div>
                    </div>
                    <div className="div-block-232 m6">
                        <div className="div-block-233">
                            <img
                                src={iot_g}
                                alt="Logo actif IOT"
                                loading="lazy"
                                decoding="defer">
                            </img>
                        </div>
                        <div className="div-block-234">
                            <img
                                src={iot}
                                alt="Logo IOT"
                                loading="lazy"
                                decoding="defer">
                            </img>
                            <div className="logo03 non">
                                <img
                                    src={logo_Amazon_BeezUp}
                                    alt="Logo inexistant"
                                    loading="lazy"
                                    decoding="defer">
                                </img>
                            </div>
                            <div className="logo02 iot2">
                                <img
                                    src={logo_Abeeway}
                                    alt="Logo Abeeway"
                                    loading="lazy"
                                    decoding="defer">
                                </img>
                            </div>
                            <div className="logo01 iot1">
                                <img
                                    src={logo_A}
                                    alt="Logo A"
                                    loading="lazy"
                                    decoding="defer">
                                </img>
                            </div>
                        </div>
                        <div className="div-block-238 iot">
                            {/* <Lottie 
                                options={defaultOptions_line_iot_gif}
                            /> */}
                        </div>
                    </div>
                    <div className="div-block-232 m7">
                        <div className="div-block-233">
                            <img
                                src={binsights_g}
                                alt="Logo inactif BI insights"
                                loading="lazy"
                                decoding="defer">
                            </img>
                        </div>
                        <div className="div-block-234">
                            <img
                                src={binsights}
                                alt="Logo actif BI insights"
                                loading="lazy"
                                decoding="defer">
                            </img>
                            <div className="logo03 non">
                                <img
                                    src={logo_Amazon_BeezUp}
                                    alt="Logo inexistant"
                                    loading="lazy"
                                    decoding="defer">
                                </img>
                            </div>
                            <div className="logo02 bi2">
                                <img
                                    src={logo_Powerbi}
                                    alt="Logo Powerbi"
                                    loading="lazy"
                                    decoding="defer">
                                </img>
                            </div>
                            <div className="logo01 bi1">
                                <img
                                    src={logo_Qlik}
                                    alt="Logo Qlik"
                                    loading="lazy"
                                    decoding="defer">
                                </img>
                            </div>
                        </div>
                        <div className="div-block-238 bi">
                            {/* <Lottie 
                                options={defaultOptions_line_biinsights_gif}
                            /> */}
                        </div>
                    </div>
                    <div className="div-block-232 m8">
                        <div className="div-block-233">
                            <img
                                src={logistic_g}
                                alt="Logo inactif 3PL Logistics"
                                loading="lazy"
                                decoding="defer">
                            </img>
                        </div>
                        <div className="div-block-234">
                            <img
                                src={logistic}
                                alt="Logo actif 3PL Logistics"
                                loading="lazy"
                                decoding="defer">
                            </img>
                            <div className="logo03 chronopost">
                                <img
                                    src={logo_Chronopost}
                                    alt="Logo Chronopost"
                                    loading="lazy"
                                    decoding="defer">
                                </img>
                            </div>
                            <div className="logo02 colissimo">
                                <img
                                    src={logo_Colissimo}
                                    alt="Logo Colissimo"
                                    loading="lazy"
                                    decoding="defer">
                                </img>
                            </div>
                            <div className="logo01">
                                <img
                                    src={logo_Ups_Mondialrelay}
                                    alt="Logo UPS et Mondialrelay"
                                    loading="lazy"
                                    decoding="defer">
                                </img>
                            </div>
                        </div>
                        <div className="div-block-238 _3pl">
                            {/* <Lottie 
                                options={defaultOptions_line_logistics_gif}
                            /> */}
                        </div>
                    </div>
                    <div className="div-block-232 m9">
                        <div className="div-block-233">
                            <img
                                src={erp_g}
                                alt="Logo inactif ERP"
                                loading="lazy"
                                decoding="defer">
                            </img>
                        </div>
                        <div className="div-block-234">
                            <img
                                src={erp}
                                alt="Logo actif ERP"
                                loading="lazy"
                                decoding="defer">
                            </img>
                            <div className="logo03 sap">
                                <img
                                    src={logo_Sap}
                                    alt="Logo SAP"
                                    loading="lazy"
                                    decoding="defer">
                                </img>
                            </div>
                            <div className="logo02 sage">
                                <img
                                    src={logo_Sage}
                                    alt="Logo Sage"
                                    loading="lazy"
                                    decoding="defer">
                                </img>
                            </div>
                            <div className="logo01">
                                <img
                                    src={logo_Odoo}
                                    alt="Logo Odoo"
                                    loading="lazy"
                                    decoding="defer">
                                </img>
                            </div>
                        </div>
                        <div className="div-block-238 erp">
                            {/* <Lottie 
                                options={defaultOptions_line_erp_gif}
                            /> */}
                        </div>
                    </div>
                    <div className="div-block-232 m10">
                        <div className="div-block-233">
                            <img
                                src={comptabilite_g}
                                alt="Logo inactif comptabilite"
                                loading="lazy"
                                decoding="defer">
                            </img>
                        </div>
                        <div className="div-block-234">
                            <img
                                src={comptabilite}
                                alt="Logo actif comptabilite"
                                loading="lazy"
                                decoding="defer">
                            </img>
                            <div className="logo03">
                                <img
                                    src={logo_Ciel}
                                    alt="Logo Ciel"
                                    loading="lazy"
                                    decoding="defer">
                                </img>
                            </div>
                            <div className="logo02">
                                <img
                                    src={logo_Numm}
                                    alt="Logo Numm"
                                    loading="lazy"
                                    decoding="defer">
                                </img>
                            </div>
                            <div className="logo01">
                                <img
                                    src={logo_Xero_Qb}
                                    alt="Logo Xero et Qb"
                                    loading="lazy"
                                    decoding="defer">
                                </img>
                            </div>
                        </div>
                        <div className="div-block-238 comptabilit">
                            {/* <Lottie 
                                options={defaultOptions_line_comptabilite_gif}
                            /> */}
                        </div>
                    </div>
                </div>
                <div className="logo-center">
                    <Lottie 
                        options={{
                        loop: true,
                        autoplay: true, 
                        animationData: props.langue === "fr-fr" ? ms_logo_center_fr : ms_logo_center_en, 
                        }}
                    />
                </div>
            </div>
        </section>
    )
}

const interaction_animation_Css = css`
.section-23 {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 67vh;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-image: url('../images/Objet-dynamique-vectoriel-copie.png');
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
  }
  
  .div-block-248 {
    position: absolute;
    left: -42.7969px;
    top: 359.0938px;
    z-index: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 90%;
    height: 90%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .lottie-animation-5 {
    width: 800px;
  }
  
  .div-block-238 {
    position: absolute;
    left: -27%;
    top: -367%;
    right: 0%;
    bottom: 0%;
    display: block;
  }
  
  .div-block-238.optimized-route {
    left: 80%;
    top: 243%;
  }
  
  .div-block-238.sellofpoint {
    left: 47%;
  }
  
  .div-block-238.comptabilit {
    left: -93%;
    top: 193%;
  }
  
  .div-block-238.ecomerce {
    left: 74%;
    top: -186%;
    right: -53%;
  }
  
  .div-block-238.iot {
    left: -84%;
    top: -321%;
  }
  
  .div-block-238.bi {
    left: -176%;
    top: -166%;
    right: 55%;
  }
  
  .div-block-238.marketplace {
    left: 60%;
    top: 219%;
  }
  
  .div-block-238._3pl {
    left: -262%;
    top: 157%;
    bottom: -127%;
  }
  
  .div-block-238.erp {
    left: -188%;
    top: 189%;
    bottom: -266%;
  }
  
  .div-block-238.crm {
    left: 69%;
    top: 144%;
    width: 270px;
    height: 100px;
  }
  
  .image-32 {
    position: static;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    display: block;
    overflow: visible;
  }
  
  .div-block-233 {
    position: absolute;
    left: -29px;
    top: 48px;
    right: 0%;
    bottom: 0%;
    width: 180px;
  }
  
  .image-33 {
    position: absolute;
    left: 22px;
    top: -21px;
    text-align: center;
  }
  
  .image-33.stripe {
    left: 50px;
    top: -10px;
  }
  
  .image-28 {
    position: static;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
  }
  
  .div-block-235 {
    position: absolute;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 90%;
    height: 90%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-image: url('../images/bg.png');
    background-position: 50% 50%;
    background-size: 1000px;
  }
  
  .logo02 {
    position: absolute;
    left: 29px;
    top: 33px;
    right: 0px;
    bottom: auto;
    display: block;
    width: 120px;
    margin-bottom: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .logo02.antsroute {
    top: 24px;
  }
  
  .logo02.presta {
    top: 25px;
  }
  
  .logo02.bi2 {
    top: 19px;
  }
  
  .logo02.iot2 {
    top: -15px;
  }
  
  .logo02._3dparty2 {
    top: -5px;
  }
  
  .marketplace {
    width: 260px;
  }
  
  .marketplace.erp {
    width: 300px;
  }
  
  .marketplace.iot {
    width: 160px;
  }
  
  .marketplace._3pl {
    width: 400px;
  }
  
  .marketplace.crm {
    width: 360px;
  }
  
  .marketplace.comptabilitline {
    width: 150px;
  }
  
  .marketplace.pointofsell {
    width: 200px;
  }
  
  .div-block-234 {
    position: absolute;
    left: -22%;
    top: -59px;
    right: 0%;
    bottom: 0%;
    display: block;
    width: 180px;
    height: 200px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-perspective: 0px;
    perspective: 0px;
    -webkit-perspective-origin: 50% 50%;
    perspective-origin: 50% 50%;
    -webkit-transform: perspective(0px);
    transform: perspective(0px);
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  
  .div-block-232 {
    position: relative;
    left: -170px;
    top: -77px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 150px;
    height: 150px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  
  .div-block-232.m7 {
    left: 384px;
    top: 4px;
  }
  
  .div-block-232.m6 {
    left: 205px;
    top: 184px;
  }
  
  .div-block-232.m9 {
    left: 388px;
    top: -572px;
  }
  
  .div-block-232.m2 {
    left: -444px;
    top: 118px;
  }
  
  .div-block-232.m5 {
    left: 27px;
    top: 284px;
  }
  
  .div-block-232.m4 {
    top: 318px;
  }
  
  .div-block-232.m3 {
    left: -342px;
    top: 268px;
  }
  
  .div-block-232.m1 {
    left: -368px;
    top: -43px;
  }
  
  .div-block-232.m10 {
    left: 189px;
    top: -747px;
  }
  
  .div-block-232.m8 {
    left: 501px;
    top: -275px;
  }
  
  .logo03 {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: auto;
    display: block;
    width: 160px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }
  
  .logo03.safeforce {
    left: 0%;
  }
  
  .logo03.ecomerce3 {
    top: 7%;
  }
  
  .logo03.non {
    display: none;
  }
  
  .logo03.sap {
    left: -3%;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .logo03.chronopost {
    top: 9%;
  }
  
  .logo01 {
    position: absolute;
    left: 41px;
    top: 65px;
    right: 0px;
    bottom: auto;
    display: block;
    width: 100px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .logo01._3dparty1 {
    top: 52px;
  }
  
  .logo01.iot1 {
    left: 35px;
    top: 59px;
  }
  
  .logo-center {
    position: absolute;
    top: 150px;
    width: 250px;
  }
  
  .section-19 {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100vh;
    margin-top: 227px;
    margin-bottom: 140px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-image: none;
    background-size: auto;
    background-repeat: repeat;
    background-attachment: scroll;
    opacity: 1;
  }
  `