import automatisation_animation_en_Desktop from "../fichier_animation/animation_en/Desktop/automatisation.json"
import data_reporting_animation_en_Desktop from "../fichier_animation/animation_en/Desktop/data_reporting.json"
import gestion_des_commandes_animation_en_Desktop from "../fichier_animation/animation_en/Desktop/gestion_des_commandes.json"
import gestion_des_interventions_animation_en_Desktop from "../fichier_animation/animation_en/Desktop/gestion_des_interventions.json"
import gestion_des_stock_animation_en_Desktop from "../fichier_animation/animation_en/Desktop/gestion_des_stocks_et_des_flux.json"
import livraison_du_dernier_kilometre_animation_en_Desktop from "../fichier_animation/animation_en/Desktop/livraison_dernier_kilometre.json"
import supply_chain_animation_en_Desktop from "../fichier_animation/animation_en/Desktop/supply_chain_etendue.json"

import automatisation_animation_en_Mobile from "../fichier_animation/animation_en/Mobile/automatisation_M.json"
import data_reporting_animation_en_Mobile from "../fichier_animation/animation_en/Mobile/data_reporting_M.json"
import gestion_des_commandes_animation_en_Mobile from "../fichier_animation/animation_en/Mobile/gestion_des_commandes_M.json"
import gestion_des_interventions_animation_en_Mobile from "../fichier_animation/animation_en/Mobile/gestion_des_interventions_M.json"
import gestion_des_stock_animation_en_Mobile from "../fichier_animation/animation_en/Mobile/gestion_des_stocks_et_des_flux_M.json"
import livraison_du_dernier_kilometre_animation_en_Mobile from "../fichier_animation/animation_en/Mobile/livraison_dernier_kilometre_M.json"
import supply_chain_animation_en_Mobile from "../fichier_animation/animation_en/Mobile/supply_chain_etendue_M.json"

export function LoadAnimation_en() {

    var tab = []
     // Variables for the first main animation en
        //Image Mobile
        var defaultOptions_automatisation_animation_Mobile = {
            loop: true,
            autoplay: true, 
            animationData: automatisation_animation_en_Mobile,
        };
        var defaultOptions_data_reporting_animation_Mobile = {
            loop: true,
            autoplay: true, 
            animationData: data_reporting_animation_en_Mobile,
        };
        var defaultOptions_gestion_des_commandes_animation_Mobile = {
            loop: true,
            autoplay: true, 
            animationData: gestion_des_commandes_animation_en_Mobile,
        };
        var defaultOptions_gestion_des_interventions_animation_Mobile = {
            loop: true,
            autoplay: true, 
            animationData: gestion_des_interventions_animation_en_Mobile,
        };
        var defaultOptions_gestion_des_stock_animation_Mobile = {
            loop: true,
            autoplay: true, 
            animationData: gestion_des_stock_animation_en_Mobile,
        };
        var defaultOptions_livraison_du_dernier_kilometre_animation_Mobile = {
            loop: true,
            autoplay: true, 
            animationData: livraison_du_dernier_kilometre_animation_en_Mobile,
        };
        var defaultOptions_supply_chain_animation_Mobile = {
            loop: true,
            autoplay: true, 
            animationData: supply_chain_animation_en_Mobile,
        };

        //Image Desktop
        var defaultOptions_automatisation_animation_Desktop = {
            loop: true,
            autoplay: true, 
            animationData: automatisation_animation_en_Desktop,
        };        
        var defaultOptions_data_reporting_animation_Desktop = {
            loop: true,
            autoplay: true, 
            animationData: data_reporting_animation_en_Desktop,
        };
        var defaultOptions_gestion_des_commandes_animation_Desktop = {
            loop: true,
            autoplay: true, 
            animationData: gestion_des_commandes_animation_en_Desktop,
        };
        var defaultOptions_gestion_des_interventions_animation_Desktop = {
            loop: true,
            autoplay: true, 
            animationData: gestion_des_interventions_animation_en_Desktop,
        };
        var defaultOptions_gestion_des_stock_animation_Desktop = {
            loop: true,
            autoplay: true, 
            animationData: gestion_des_stock_animation_en_Desktop,
        };
        var defaultOptions_livraison_du_dernier_kilometre_animation_Desktop = {
            loop: true,
            autoplay: true, 
            animationData: livraison_du_dernier_kilometre_animation_en_Desktop,
        };        
        var defaultOptions_supply_chain_animation_Desktop = {
            loop: true,
            autoplay: true, 
            animationData: supply_chain_animation_en_Desktop,
        };

    tab.push(defaultOptions_automatisation_animation_Desktop,defaultOptions_supply_chain_animation_Desktop,defaultOptions_gestion_des_interventions_animation_Desktop,defaultOptions_gestion_des_commandes_animation_Desktop,defaultOptions_livraison_du_dernier_kilometre_animation_Desktop,defaultOptions_data_reporting_animation_Desktop,defaultOptions_gestion_des_stock_animation_Desktop)
    tab.push(defaultOptions_automatisation_animation_Mobile,defaultOptions_supply_chain_animation_Mobile,defaultOptions_gestion_des_interventions_animation_Mobile,defaultOptions_gestion_des_commandes_animation_Mobile,defaultOptions_livraison_du_dernier_kilometre_animation_Mobile,defaultOptions_data_reporting_animation_Mobile,defaultOptions_gestion_des_stock_animation_Mobile)
    return(tab)
}