import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"
import { css } from "@emotion/core"
import { colors } from "../styles/styles"
import SwiperCore, { Navigation, Controller } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import Image from "../components/image"
import SEO from "../components/SEO"
import Slider from "../components/slider"
import linkResolver from "../utils/linkResolver"
import LocalizedLink from "../components/localizedLink"
import Benefits from "../components/benefits"
import Contact from "../components/contact"
import Feedback from "../components/feedback"
import { redirectToUserLanguage } from "../utils/utils"
import { isExternalLink, isDocumentLink } from "../utils/utils"
import Newsletter from "../components/newsletter"

//first animation
import FirstAnimation from "../components/main_animation/firstanimationlottie"
//import for the second animation
import InteractionLogo_animation from "../components/seconde_animation/interaction-logo"


SwiperCore.use([Navigation, Controller])

const IndexPage = ({ data, pageContext: { locale }, location }) => {
  const IndexPageData = data.prismic.homepage
  const IndexFieldsData = data.prismic.allFieldspages
  const [popupVideo, setPopupVideo] = useState(false)
  const [featureSwiperThumbs, setFeatureSwiperThumbs] = useState(null)
  const [featureSwiper, setFeatureSwiper] = useState(null)
  //parametre for swiper "slider-main"
  const [dotSelected, setDotSelected] = useState(null)
  const [swipeActivate, setSwipeActivate] = useState(true)
  let sliderFields = []
  let sliderShop = []
  // get and set the sectors/fields to be displayed on the homepage, in the correct order
  let fieldDisplayedOnHome = IndexFieldsData.edges[0].node.body.filter(
    displayed => displayed.primary.field_isdisplayedonhome > 0
  )
  fieldDisplayedOnHome = fieldDisplayedOnHome.sort(
    (a, b) =>
      a.primary.field_isdisplayedonhome > b.primary.field_isdisplayedonhome
  )
  fieldDisplayedOnHome.forEach(field => {
    const slide = {
      id: field.primary.field_id,
      name: RichText.asText(field.primary.field_title),
      sharp: field.primary.field_imageSharp,
      image: field.primary.field_image,
    }
    sliderFields.push(slide)
  })

  IndexPageData.shop_products.forEach(product => {
    const slide = {
      id: product.shop_products_id,
      name: product.shop_products_name,
      sharp: product.shop_products_imageSharp,
      image: product.shop_products_image,
      link: product.shop_product_link,
    }
    sliderShop.push(slide)
  })

  const blogPageData = data.prismic.allBlogpages.edges[0].node

  return location.pathname == "/" ? <div>{redirectToUserLanguage()}</div> : (
    <>
      <SEO
        title={IndexPageData.meta_title}
        desc={IndexPageData.meta_description}
        metadata={data.site.siteMetadata}
        locale={locale}
        pathname={location.pathname}
      />

      <div className="container-fluid" css={homeCss}>
        <section className="section is-themeGradientH">
          <div className="container">
            <div className="columns is-multiline is-centered">
              <div className="column is-6 headline">
                <h1 className="title  is-size-1-desktop is-size-3-mobile has-text-centered has-text-weight-normal">
                  {RichText.asText(IndexPageData.title)}
                </h1>
                <h2 className="title is-5 has-text-centered has-text-weight-extralight">
                  {RichText.asText(IndexPageData.subtitle)}
                </h2>
                <div className="has-text-centered">
                  {/* <RichText render={IndexPageData.content} /> */}
                </div>
                <br />
                {IndexPageData.call_to_action.map(action => {
                  return (
                    <div
                      key={action.id}
                      className="is-centered has-text-centered"
                    >
                      {action.call_to_action_link &&
                        isExternalLink(action.call_to_action_link) && (
                          <a
                            href={action.call_to_action_link.url}
                            className="button is-rounded button__primary has-text-white is-medium"
                            target={action.call_to_action_link.target}
                            rel="noopener noreferrer nofollow"
                          >
                            {action.call_to_action_text}
                          </a>
                        )}
                      {action.call_to_action_link &&
                        isDocumentLink(action.call_to_action_link) && (
                          <LocalizedLink
                            to={action.call_to_action_link._meta.uid}
                            className="button is-rounded button__primary has-text-white"
                          >
                            {action.call_to_action_text}
                          </LocalizedLink>
                        )}

                      {IndexPageData.video && ( // popup video
                        <>
                          <div className="is-size-2">
                            <i
                              className="icon icon-play_btn"
                              onClick={() => setPopupVideo(true)}
                              css={{ cursor: "pointer", position: "relative" }}
                            ></i>
                          </div>
                          {popupVideo && (
                            <div className="modal is-active">
                              <div
                                className="modal-background"
                                onClick={() => setPopupVideo(false)}
                              ></div>
                              <div className="modal-content">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: IndexPageData.video.html,
                                  }}
                                />
                              </div>
                              <button
                                className="modal-close is-large"
                                aria-label="close"
                                onClick={() => setPopupVideo(false)}
                              ></button>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  )
                })}
              </div>
            </div>
            {/* <div className="columns is-centered">
              <div className="column main__image">
                {IndexPageData.main_image && (
                  <Image
                    sharp={IndexPageData.main_imageSharp}
                    image={IndexPageData.main_image}
                  />
                )}
              </div>
            </div> */}
          </div>
        </section>

        <section className="Main_animation">
          <FirstAnimation
            //detection langue user for build animations in the good language
            langue={locale}
            loading="lazy"
            decoding="async"
          />
        </section>

        <section className="section is-medium">
          <div className="container">
            <div className="columns">
              <div className="column is-4">
                {IndexPageData.app_image && (
                  <a
                    href={IndexPageData.app_store[1].store_link.url}
                    target={IndexPageData.app_store[1].store_link.target}
                    rel="noopener noreferrer nofollow"
                  >
                    <Image
                      sharp={IndexPageData.app_imageSharp}
                      image={IndexPageData.app_image}
                    />
                  </a>
                )}
              </div>
              <div className="column is-4">
                <h3 className="title is-4 is-uppercase has-text-centered has-text-weight-semibold">
                  {RichText.asText(IndexPageData.app_title)}
                </h3>
                <p className="has-text-centered">
                  {RichText.asText(IndexPageData.app_subtitle)}
                </p>
                <ul className="is-centered has-text-centered">
                  {IndexPageData.app_store.map(store => {
                    return (
                      <li key={store.store_name} className="badges__app">
                        <a
                          href={store.store_link.url}
                          target={store.store_link.target}
                          rel="noopener noreferrer nofollow"
                        >
                          {store.store_image && (
                            <figure className="image">
                              <Image
                                sharp={store.store_imageSharp}
                                image={store.store_image}
                              />
                            </figure>
                          )}
                        </a>
                      </li>
                    )
                  })}
                </ul>
              </div>
              <div className="column is-4">
                {IndexPageData.app_image1 && (
                  <a
                    href={IndexPageData.app_store[0].store_link.url}
                    target={IndexPageData.app_store[0].store_link.target}
                    rel="noopener noreferrer nofollow"
                  >
                    <Image
                      sharp={IndexPageData.app_image1Sharp}
                      image={IndexPageData.app_image1}
                    />
                  </a>
                )}
              </div>
            </div>
          </div>
        </section>

        <Benefits
          benefits={{
            title: IndexPageData.benefits_title,
            subtitle: IndexPageData.benefits_subtitle,
            benefits: IndexPageData.benefits_list,
          }}
        />

        <section
          className="section"
          css={{ paddingLeft: "0", paddingRight: "0" }}
          onClick={() => {if(swipeActivate == false && dotSelected !=false){setSwipeActivate(true)}}}
        >
          <div css={slideFeatureCss}>
            <div className="container">
              <div className="slider-main">
                <Swiper
                  centeredSlides={true}
                  loop={true}
                  autoplay={{
                    delay: 5000,
                    disableOnInteraction: false
                  }}
                  loopedSlides={1}
                  onSwiper={setFeatureSwiperThumbs}
                  slideToClickedSlide={true}
                  controller={{ control: featureSwiper }}
                  navigation={{
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                  }}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    1024: {
                      slidesPerView: 3,
                      spaceBetween: 30,
                    },
                  }}
                  className="swiper-features"
                  onSlideChange={() => setTimeout}
                  onSlideChangeTransitionStart={() => {setDotSelected(false);setSwipeActivate(true)}}
                  //gestion de l'affichage de la slide suivante
                  allowSlideNext={swipeActivate}
                  //distance minimal en pixel pour que le slider prenne en compte l'action 
                  threshold={30}
                >
                  {IndexPageData.features.map((feature, i) => {
                    return (
                      <SwiperSlide key={i}>
                        <div
                          key={i}
                          className="column has-text-centered has-text-weight-light is-size-5"
                        >
                          {feature.primary.feature_image_icon && (
                            <Image
                              sharp={feature.primary.feature_image_iconSharp}
                              image={feature.primary.feature_image_icon}
                            />
                          )}
                          {RichText.asText(feature.primary.feature_title)}
                        </div>
                      </SwiperSlide>
                    )
                  })}
                </Swiper>

                <div
                  role="button"
                  name="Previous slide"
                  aria-label="Previous slide"
                  className="swiper-button-prev"
                  onClick={() => {if(swipeActivate == false){setSwipeActivate(true)}}}
                ></div>
                <div
                  role="button"
                  name="Next slide"
                  aria-label="Next slide"
                  className="swiper-button-next"
                  onClick={() => {if(swipeActivate == false){setSwipeActivate(true)}}}
                ></div>
              </div>
            </div>

            <div className="columns is-centered is-multiline slide__features-screenshot">
              <div className="features-bg"></div>
              <Swiper
                onSwiper={setFeatureSwiper}
                controller={{ control: featureSwiperThumbs }}
                loop={true}
                loopedSlides={1}
                //gestion de l'affichage de la slide précédente et suivante
                allowSlidePrev={swipeActivate}
                allowSlideNext={swipeActivate}
                //distance minimal en pixel pour que le slider prenne en compte l'action 
                threshold={30}
              >
                {IndexPageData.features.map((feature, i) => (
                  <SwiperSlide key={i}>
                    <div
                      className="column is-8 is-10-touch has-text-centered has-text-white is-size-4 is-size-6-touch"
                      css={{ zIndex: 1000, paddingTop: "100px" }}
                    >
                      {RichText.render(
                        feature.primary.feature_description,
                        linkResolver
                      )}
                    </div>
                    <div
                      className="column is-8 is-10-mobile container"
                      css={{ position: "relative" }}
                    >
                      <div className="interactiveDots">
                        {feature.fields.map(dot => (
                          <>
                            {dot.id !== null && (
                              <div
                                key={dot.id}
                                className="dot icon-target is-size-3-desktop is-size-5-touch"
                                css={{ top: `${dot.y}%`, left: `${dot.x}%` }}
                                onClick={() => {
                                  setDotSelected(dot.id);
                                  if(swipeActivate==true) {
                                    setSwipeActivate(false)
                                  }
                                }}
                              />
                            )}
                            {dot.id !== null && dotSelected === dot.id && (
                              <div className="dotDescription notification">
                                <button
                                  className="icon-cross"
                                  onClick={() => {
                                    setDotSelected(false);
                                    if(swipeActivate==false) {
                                      setSwipeActivate(true)
                                    }
                                  }}
                                />
                                {dot.feature_step_image && (
                                  <Image
                                    sharp={dot.feature_step_imageSharp}
                                    image={dot.feature_step_image}
                                  />
                                )}
                                {dot.feature_step_content &&
                                  RichText.render(
                                    dot.feature_step_content,
                                    linkResolver
                                  )}
                              </div>
                            )}
                          </>
                        ))}
                      </div>
                      {feature.primary.feature_image_main && (
                        <div
                          style={dotSelected ? { opacity: 0.6 } : {}}
                          onClick={() => setDotSelected(false)}
                        >
                          <Image
                            sharp={feature.primary.feature_image_mainSharp}
                            image={feature.primary.feature_image_main}
                          />
                        </div>
                      )}
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </section>

        <section className="schema_interaction_animation" loading="lazy" decoding="async">
          <div className="second_animation">
              <InteractionLogo_animation
              //detection langue user for build animations in the good language
                langue={locale}
                loading="lazy"
              />
          </div>
        </section>
        
        <section className="section is-theme2">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-6">
                <h3 className="title is-3 has-text-centered has-text-weight-semibold">
                  {RichText.asText(IndexPageData.fields_title)}
                </h3>
                <p className="has-text-centered">
                  {IndexPageData.fields_subtitle &&
                    RichText.asText(IndexPageData.fields_subtitle)}
                </p>
              </div>
            </div>
            <Slider
              className="swiper-fields"
              slidesList={sliderFields}
              sliderId="fields"
            />
            <div className="has-text-centered">
              {IndexPageData.fields_call_to_actions.map((action, i) => {
                return (
                  <>
                    {action.fields_call_to_action_link && (
                      <LocalizedLink
                        key={i}
                        to={action.fields_call_to_action_link._meta.uid}
                        className="button is-rounded button__primary has-text-white"
                      >
                        {action.fields_call_to_action_name}
                        <span className="icon-arrow_right pl-2"></span>
                      </LocalizedLink>
                    )}
                  </>
                )
              })}
            </div>
          </div>
        </section>

        <section className="section is-themeGradientV">
          <div className="container">
            <h3 className="title is-3 has-text-centered">
              {RichText.asText(IndexPageData.shop_title)}
            </h3>
            <Slider
              className="swiper-shop"
              slidesList={sliderShop}
              layout="titleFirst"
              sliderId="shop"
            />
            <div className="has-text-centered">
              {IndexPageData.shop_call_to_actions.map((action, i) => {
                if (
                  action.shop_call_to_action_link._linkType === "Link.document"
                ) {
                  return (
                    <LocalizedLink
                      key={i}
                      to={action.shop_call_to_action_link._meta.uid}
                      className="button is-rounded button__primary has-text-white"
                    >
                      {action.shop_call_to_action_name}
                    </LocalizedLink>
                  )
                }
                if (action.shop_call_to_action_link._linkType === "Link.web") {
                  return (
                    <a
                      key={i}
                      href={action.shop_call_to_action_link.url}
                      className="button is-rounded button__primary has-text-white"
                      target="_blank"
                      rel="noopener noreferrer nofollow"
                    >
                      {action.shop_call_to_action_name}
                      <span className="icon-arrow_right pl-2"></span>
                    </a>
                  )
                }
                return true
              })}
            </div>
          </div>
        </section>

        <section className="section clients__section">
          <div className="container">
            <h3 className="title is-3 has-text-centered">
              {RichText.asText(IndexPageData.clients_title)}
            </h3>
            <div className="columns is-centered">
              <div className="column is-12">
                <Swiper autoplay navigation loop>
                  {IndexPageData.body.map((slideClient, i) => {
                    if (slideClient.type === "client_slideshow") {
                      return (
                        <SwiperSlide key={`clientSlide-${i}`}>
                          <div className="columns is-multiline is-centered is-mobile py-6">
                            {slideClient.fields.map((clientLogo, y) => (
                              <div
                                key={`clientLogo-${y}`}
                                className="column is-2 clients"
                              >
                                <Image
                                  sharp={
                                    clientLogo.client_slideshow_slide_imageSharp
                                  }
                                  image={
                                    clientLogo.client_slideshow_slide_image
                                  }
                                />
                              </div>
                            ))}
                          </div>
                        </SwiperSlide>
                      )
                    }
                  })}
                </Swiper>
              </div>
            </div>
          </div>
        </section>

        <Feedback feedback={data.prismic.allFeedbackcomponents.edges[0].node} />

        <section id="contactUs" className="section is-medium">
          <Contact contactData={data.prismic.allContactforms} />
        </section>
        <section className="section">
          <Newsletter newsletter={blogPageData} />
        </section>
      </div>
    </>
  )
}

export default IndexPage

IndexPage.propTypes = {
  data: PropTypes.shape({
    prismic: PropTypes.object.isRequired,
  }).isRequired,
}

export const indexQuery = graphql`
  query IndexQuery($locale: String!) {
    prismic {
      homepage(lang: $locale, uid: "homepage") {
        meta_title
        meta_description
        title
        subtitle
        video
        call_to_action {
          id
          call_to_action_text
          call_to_action_link {
            _linkType
            ... on PRISMIC__Document {
              _meta {
                uid
              }
            }
            ... on PRISMIC__ExternalLink {
              url
              target
            }
          }
        }
        main_image
        main_imageSharp {
          extension
          publicURL
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }

        app_title
        app_subtitle
        app_store {
          store_name
          store_image
          store_imageSharp {
            extension
            publicURL
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
          store_link {
            __typename
            ... on PRISMIC__ExternalLink {
              url
              target
            }
          }
        }
        app_image
        app_imageSharp {
          extension
          publicURL
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        app_image1
        app_image1Sharp {
          extension
          publicURL
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        benefits_title
        benefits_subtitle
        benefits_list {
          benefits_id
          benefits_item_name
          benefits_item_image
          benefits_item_imageSharp {
            extension
            publicURL
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }

        features {
          ... on PRISMIC_HomepageFeaturesFeature {
            primary {
              feature_title
              feature_description
              feature_image_icon
              feature_image_iconSharp {
                extension
                publicURL
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_tracedSVG
                  }
                }
              }
              feature_image_main
              feature_image_mainSharp {
                extension
                publicURL
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_tracedSVG
                  }
                }
              }
            }
            fields {
              id
              feature_step_content
              x
              y
              feature_step_image
              feature_step_imageSharp {
                extension
                publicURL
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_tracedSVG
                  }
                }
              }
            }
          }
        }

        overview_image
        overview_imageSharp {
          extension
          publicURL
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }

        fields_title
        fields_subtitle
        fields_call_to_actions {
          fields_call_to_action_name
          fields_call_to_action_link {
            __typename
            ... on PRISMIC__Document {
              _meta {
                uid
              }
            }
          }
        }

        shop_title
        shop_call_to_actions {
          shop_call_to_action_name
          shop_call_to_action_link {
            _linkType
            ... on PRISMIC__Document {
              _meta {
                uid
              }
            }
            ... on PRISMIC__ExternalLink {
              url
              target
            }
          }
        }
        shop_products {
          shop_products_id
          shop_products_name
          shop_product_link {
            _linkType
            ... on PRISMIC__ExternalLink {
              url
              target
            }
          }
          shop_products_image
          shop_products_imageSharp {
            extension
            publicURL
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }

        clients_title
        body {
          ... on PRISMIC_HomepageBodyClient_slideshow {
            type
            fields {
              client_slideshow_slide_image
              client_slideshow_slide_imageSharp {
                extension
                publicURL
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_tracedSVG
                  }
                }
              }
            }
          }
        }
      }

      allFieldspages(lang: $locale) {
        edges {
          node {
            _meta {
              uid
              type
            }
            ...Sectors
          }
        }
      }

      allBlogpages(lang: $locale) {
        edges {
          node {
            related_posts
            share
            newsletter_title
            newsletter_subtitle
            newsletter_placeholder
            newsletter_button
            newsletter_success
            newsletter_image
            newsletter_imageSharp {
              extension
              publicURL
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
      }


      ...Feedback
      ...Contact
    }
    site {
      ...SiteInformation
    }
  }
`

const slideFeatureCss = css`
  .section {
    padding: 3rem 0 !important;
  }

  .slider__features-bg {
    position: relative;
  }

  .features-bg {
    background-image: url(../images/slider__features-bg.png);
    background-repeat: no-repeat;
    background-position: top center;
    margin-top: 10px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    position: absolute;
    height: 40rem;
    width: 100%;
    background-size: cover;
  }

  .swiper-features {
    img {
      height: 60px;
      width: 60px;
    }
    .swiper-slide {
      /* max-width: 300px; */
      width: auto;
    }
    .gatsby-image-wrapper {
      margin-bottom: 20px;
    }
  }

  .swiper-slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto;
  }

  .slider-main {
    .swiper-slide {
      cursor: pointer;
    }
    &:last-child {
      border-right: 0px solid black;
    }

    .swiper-slide-active {
      border-right: 1px solid rgba(90, 104, 127, 0.14);
      border-left: 1px solid rgba(90, 104, 127, 0.14);
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.33) 0%,
        rgba(217, 223, 230, 0.33) 50%,
        rgba(255, 255, 255, 0.33) 100%
      );
    }
  }
`

const homeCss = css`
  .is-themeGradientH {
    background-image: linear-gradient(
      to left,
      #f0f5ff,
      rgba(255, 255, 255, 0.5),
      #f0f5ff
    );
    background-size: 100% 60%;
    background-repeat: no-repeat;
    padding-bottom: 9rem;
  }
  .headline {
    margin-top: 60px;
  }
  .clients__section {
    .gatsby-image-wrapper {
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    img {
      max-height: 40px;
    }
    .clients {
      min-width: 250px;
    }
  }

  .main__image {
    .gatsby-image-wrapper {
      text-align: center;
    }
  }

  .badges__app {
    margin-top: 25px;
    .gatsby-image-wrapper {
      max-width: 180px;
      margin: 0 auto;
    }
  }

  .benefits__button {
    justify-content: left;
    border: 0;
    background-color: #c1c7e7;
    color: #3364c6;

    span {
      color: ${colors.blue};
      opacity: 34%;
      font-size: 25px;
      padding-left: 30px;
      padding-right: 50px;
      height: auto;
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    &.active,
    &:hover {
      box-shadow: 0 12px 15px 0 rgba(61, 101, 191, 0.31);
      background-color: ${colors.blue};
      color: #fff;

      span {
        color: #f7b500;
        opacity: 1;
      }
    }
  }

  .modal-content, .modal-card {
    width: 100%;
  }

  .interactiveDots {
    display: flex;
    justify-content: center;

    & + div {
      .gatsby-image-wrapper {
        border-radius: 20px;
        -webkit-box-shadow: 0px 5px 15px 10px rgba(78, 90, 114, 0.19);
        box-shadow: 0px 5px 15px 10px rgba(78, 90, 114, 0.19);
        background-color: #fff;
      }
    }

    .dot {
      position: absolute;
      z-index: 10;
      background-color: #fff;
      -webkit-box-shadow: 0px 2px 3px 0px rgba(255, 255, 255, 1);
      -moz-box-shadow: 0px 2px 3px 0px rgba(255, 255, 255, 1);
      box-shadow: 0px 2px 3px 0px rgba(255, 255, 255, 1);
      color: ${colors.orange};
      border-radius: 20px;
      cursor: pointer;
    }

    .dotDescription {
      background-color: #eaecf3;
      position: absolute;
      z-index: 1010;
      border-radius: 10px;
      width: auto;
      height: auto;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      overflow: hidden;
      width: 100%;
      .gatsby-image-wrapper {
        margin: 0 auto;
        max-width: 500px;
        max-height: 300px;
      }
      .icon-cross {
        color: ${colors.blue};
        background: none;
        border: 0;
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 15px;
        cursor: pointer;
      }
    }
  }

  .Main_animation {
    position: relative;
    display: flex;
    width: auto;
    height: 80vh;
    margin-top: 40px;
    }
  

  @media (max-width: 767px) {
    .is-themeGradientH {
      background-size: 100% 85%;
      padding-bottom: 3rem;
    }
    .main__image {
      .gatsby-image-wrapper {
        margin-top: -50px;
        margin-bottom: -75px;
      }
    }
    .clients__section {
      .swiper-button-prev,
      .swiper-button-next {
        background-color: transparent;
      }
    }
    .benefits__button {
      display: flex;
      flex-direction: column;
      height: auto;
      white-space: inherit;

      span {
        padding-left: 0;
        padding-right: 0;
      }
    }
    .dotDescription {
      max-width: 225px !important;
      max-height: 600px !important;
    }
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    .main__image {
      .gatsby-image-wrapper {
        margin-top: -150px;
        margin-bottom: -125px;
      }
    }
    .modal-content {
      iframe {
        width: 720px;
        height: 405px;
      }
    }
  }

  @media (min-width: 1024px) {
    .headline {
      margin-top: 100px;
    }
    .main__image {
      .gatsby-image-wrapper {
        margin-top: -100px;
        margin-bottom: -100px;
      }
    }

    .benefits__button {
      span {
        height: 0;
      }
    }

    .modal-content {
      iframe {
        width: 960px;
        height: 540px;
      }
    }
  }

  @media (max-width: 991px) {
    .Main_animation {
      height: 1600px;
    }
  }

  @media (max-width: 479px) {
    .Main_animation {
      height: 2250px;
    }
  }
`
